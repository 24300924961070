<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow px-4 lg:px-0'>
      <div class='py-8 lg:py-16 relative'>
        <div class='max-w-4xl'>
          <div class='mb-4 lg:mb-8 flex flex-row justify-between items-center'>
            <h1 class='text-3xl font-bold'>Current Submissions ({{myAbstracts.length}})</h1>
            <button v-if='isAbstractSubmissionPeriod'
              @click='gotoNewAbstract'
              class='py-3 px-8 text-sm uppercase text-center bg-gray-900 text-white rounded-md hover:shadow-md opacity-80 hover:opacity-100'>
              Submit A New Abstract
            </button>
          </div>
          <div v-for='abstract in myAbstracts'
            :key='`my-abstract-${abstract.id}`'
            class='border rounded-md mb-8 relative'
            style='height: 600px;'>
            <div class='absolute top-4 right-4 flex flex-row z-10 gap-x-4'>
              <button
                @click='gotoAbstract(abstract)'
                class='standard-transition h-12 px-4 border border-gray-300 hover:border-gray-500 hover:text-gray-800 hover:shadow-md flex flex-row justify-center items-center text-xs uppercase rounded-md'>
                <edit-icon class='h-4' />
                edit
              </button>
              <button v-if='showDeleteControl'
                @click='removeAbstract(abstract)'
                class='standard-transition h-12 w-12 border border-gray-300 hover:border-red-400 hover:text-red-600 hover:shadow-md flex flex-row justify-center items-center rounded-md'>
                <trash-x-icon class='h-4' />
              </button>
            </div>
            <abstract-submission-preview
              :abstract='abstract'
              :show-accepted-status-badge='false' 
              class='overflow-y-auto'
              style='height: 600px;' />
          </div>
          
          <div v-if='hasNoAbstracts && isAbstractSubmissionPeriod'
            class='rounded-md bg-gray-100 py-8 px-4 mb-8 text-center text-gray-600'>
            <p class='mb-2'>You have not yet submitted any abstracts.</p>
            Please <span class='underline cursor-pointer hover:bg-gray-300' @click='gotoNewAbstract'>submit an abstract</span>.
          </div>
          <div v-else-if='hasNoAbstracts && !isAbstractSubmissionPeriod'>
            <p class='mb-2'>Abstract submission deadline has passed.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import { ArrowNarrowLeftIcon, EditIcon, TrashXIcon } from 'vue-tabler-icons'
import dayjs from 'dayjs'

export default {
  name: 'AbstractSubmissions',
  components: {
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'abstract-submission-preview': () => import('@/components/common/abstract-submission/AbstractSubmissionPreview.vue'),
    ArrowNarrowLeftIcon,
    EditIcon, 
    TrashXIcon,
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
      'showingEventId',
      'showingEventAbstractSubmissionsConfigurations',
      'eventConfigAbstractSubmissionDeadline',
    ]),
    ...mapState('abstracts', [
      'myAbstracts',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    pageNavigationComponent () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.pageNavigationComponent) ? this.showingEventAbstractSubmissionsConfigurations.pageNavigationComponent : ''
    },
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    sidebarConfigurations () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.sidebarConfigurations) ? this.showingEventAbstractSubmissionsConfigurations.sidebarConfigurations : {}
    },
    hasNoAbstracts () {
      return this.myAbstracts.length == 0
    },
    isAbstractSubmissionPeriod () {
      return dayjs(this.eventConfigAbstractSubmissionDeadline).isAfter(dayjs(new Date()))
    },
    isAbstractSubmissionPeriodPreviewClass () {
      return this.isAbstractSubmissionPeriod ? 'cursor-pointer' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    showDeleteControl () {
      return false // Hana (2023.07.21): Set to false to just hide for now for icola
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'getRegistrationData',
    ]),
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('abstracts', [
      'getMyAbstracts',
      'deleteAbstract',
    ]),
    goToMain () {
      this.$router.push({name: 'Main'})
    },
    goToAbstractSubmissionGuideline () {
      this.$router.push({name: 'AbstractSubmissionGuideline'})
    },
    gotoAbstract (abstract) {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: abstract.id }})
      }
    },
    gotoNewAbstract () {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: 0 }})
      }
    },
    removeAbstract(abstract) {
      this.$confirm(`Are you sure you want to delete the abstract titled ${abstract.title}`, {
        type: 'success',
        confirmButtonText: 'Yes, Delete',
        cancelButtonText: 'No, Keep'
      }).then(() => {
        this.deleteAbstract(abstract.id).then(() => {
          // do nothing
        })
      })
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      } else {
        this.showFullpageLoadingIndicator()
        this.getMyAbstracts(this.showingEventId).then(() => {
          this.hideFullpageLoadingIndicator()
        })
      }
    })
  },
}
</script>
